<template>
  <div class="chat-wrap">
    <div class="chat-header__wrap">
      <div class="header-content">
        <div class="header-title">
          {{ $t('chatgpt.info.title') }}
        </div>
        <div class="header-desc">
          {{ $t('chatgpt.info.desc') }}
        </div>
      </div>
      <img :src="require('@/assets/img/home/chat-top-bg.png')" alt="">
    </div>
    <div class="chat-content__wrap">
      <div class="content-item">
        <div class="info-wrap">
          <div class="info-title">
            {{ $t('chatgpt.info.step1.title') }}
          </div>
          <div class="info-desc">
            {{ $t('chatgpt.info.step1.desc') }}
          </div>
          <div class="info-example">
            {{ $t('chatgpt.info.step1.line1') }}
            <br />
            {{ $t('chatgpt.info.step1.line2') }}
            <br />
            {{ $t('chatgpt.info.step1.line3') }}
          </div>
        </div>
        <div class="loop-wrap">
          <swiper
            :slides-per-view="1"
            :space-between="0"
            loop
            :modules="modules"
            :pagination="{
              clickable: true,
            }"
          >
            <swiper-slide>
              <img class="swiper-img" :src="$isZh() ? require('@/assets/img/home/chat-loop/1-1.png') : require('@/assets/img/home/chat-loop/1-1-en.png')" alt="">
            </swiper-slide>
            <swiper-slide>
              <img class="swiper-img" :src="$isZh() ? require('@/assets/img/home/chat-loop/1-2.png') : require('@/assets/img/home/chat-loop/1-2-en.png')" alt="">
            </swiper-slide>
            <swiper-slide>
              <img class="swiper-img" :src="$isZh() ? require('@/assets/img/home/chat-loop/1-3.png') : require('@/assets/img/home/chat-loop/1-3-en.png')" alt="">
            </swiper-slide>
          </swiper>
        </div>
      </div>
      <div class="content-item">
        <div class="loop-wrap">
          <swiper
            :slides-per-view="1"
            :space-between="0"
            loop
            :modules="modules"
            :pagination="{
              clickable: true,
            }"
          >
            <swiper-slide>
              <img class="swiper-img" :src="$isZh() ? require('@/assets/img/home/chat-loop/2-1.png') : require('@/assets/img/home/chat-loop/2-1-en.png')" alt="">
            </swiper-slide>
            <swiper-slide>
              <img class="swiper-img" :src="$isZh() ? require('@/assets/img/home/chat-loop/2-2.png') : require('@/assets/img/home/chat-loop/2-2-en.png')" alt="">
            </swiper-slide>
            <swiper-slide>
              <img class="swiper-img" :src="$isZh() ? require('@/assets/img/home/chat-loop/2-3.png') : require('@/assets/img/home/chat-loop/2-3-en.png')" alt="">
            </swiper-slide>
          </swiper>
        </div>
        <div class="info-wrap">
          <div class="info-title">
            {{ $t('chatgpt.info.step2.title') }}
          </div>
          <div class="info-desc">
            {{ $t('chatgpt.info.step2.desc') }}
          </div>
          <div class="info-example">
            {{ $t('chatgpt.info.step2.line1') }}
            <br />
             {{ $t('chatgpt.info.step2.line2') }}
            <br />
             {{ $t('chatgpt.info.step2.line3') }}
          </div>
        </div>
      </div>
      <div class="content-item">
        <div class="info-wrap">
          <div class="info-title">
            {{ $t('chatgpt.info.step3.title') }}
          </div>
          <div class="info-desc">
            {{ $t('chatgpt.info.step3.desc') }}
          </div>
          <div class="info-example">
            {{ $t('chatgpt.info.step3.line1') }}
            <br />
            {{ $t('chatgpt.info.step3.line2') }}
            <br />
            {{ $t('chatgpt.info.step3.line3') }}
          </div>
        </div>
        <div class="loop-wrap">
          <swiper
            :slides-per-view="1"
            :space-between="0"
            loop
            :modules="modules"
            :pagination="{
              clickable: true,
            }"
          >
            <swiper-slide>
              <img class="swiper-img" :src="$isZh() ? require('@/assets/img/home/chat-loop/3-1.png') : require('@/assets/img/home/chat-loop/3-1-en.png')" alt="">
            </swiper-slide>
            <swiper-slide>
              <img class="swiper-img" :src="$isZh() ? require('@/assets/img/home/chat-loop/3-2.png') : require('@/assets/img/home/chat-loop/3-2-en.png')" alt="">
            </swiper-slide>
            <swiper-slide>
              <img class="swiper-img" :src="$isZh() ? require('@/assets/img/home/chat-loop/3-3.png') : require('@/assets/img/home/chat-loop/3-3-en.png')" alt="">
            </swiper-slide>
          </swiper>
        </div>
      </div>
      <div class="content-item">
        <div class="loop-wrap">
          <swiper
            :slides-per-view="1"
            :space-between="0"
            loop
            :modules="modules"
            :pagination="{
              clickable: true,
            }"
          >
            <swiper-slide>
              <img class="swiper-img" :src="$isZh() ? require('@/assets/img/home/chat-loop/4-1.png') : require('@/assets/img/home/chat-loop/4-1-en.png')" alt="">
            </swiper-slide>
            <swiper-slide>
              <img class="swiper-img" :src="$isZh() ? require('@/assets/img/home/chat-loop/4-2.png') : require('@/assets/img/home/chat-loop/4-2-en.png')" alt="">
            </swiper-slide>
            <swiper-slide>
              <img class="swiper-img" :src="$isZh() ? require('@/assets/img/home/chat-loop/4-3.png') : require('@/assets/img/home/chat-loop/4-3-en.png')" alt="">
            </swiper-slide>
          </swiper>
        </div>
        <div class="info-wrap">
          <div class="info-title">
            {{ $t('chatgpt.info.step4.title') }}
          </div>
          <div class="info-desc">
            {{ $t('chatgpt.info.step4.desc') }}
          </div>
          <div class="info-example">
            {{ $t('chatgpt.info.step4.line1') }}
            <br />
            {{ $t('chatgpt.info.step4.line2') }}
            <br />
            {{ $t('chatgpt.info.step4.line3') }}
          </div>
        </div>
      </div>
    </div>
    <div class="chat-footer__wrap">
      <div class="footer-content">
        <div class="footer-title">{{ $t('chatgpt.info.footerText') }}</div>
      </div>
      <img :src="require('@/assets/img/home/chat-bottom-bg.png')" alt="">
    </div>
  </div>
</template>

<script setup>
import { ref } from 'vue'
import { Swiper, SwiperSlide } from 'swiper/vue/swiper-vue'
import { Pagination, Autoplay } from 'swiper'
import 'swiper/swiper.min.css'

const modules = ref([Pagination, Autoplay])
</script>

<style lang="scss" scoped>
.chat-wrap {
  width: 100%;
  min-width: 1344px;
  overflow-x: auto;
  padding-bottom: 60px;
  background: #10121B;
  // background-image: url('~@/assets/img/home/chat-top-bg.png');
  // background-size: 100%;
  // background-repeat: no-repeat;
  .chat-header__wrap {
    position: relative;
    &>img {
      width: 100%;
    }
    .header-content {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      width: 1200px;
      box-sizing: border-box;
      padding: 24px;
      margin: auto;
      z-index: 1;
      display: flex;
      flex-direction: column;
      // align-items: center;
      justify-content: center;
      .header-title {
        font-size: 42px;
        font-weight: bold;
        line-height: 70px;
        color: #FFFFFF;
      }
      .header-desc {
        margin-top: 10px;
        width: 620px;
        font-size: 18px;
        line-height: 32px;
        color: #FFFFFF;
      }
    }
  }
  .chat-content__wrap {
    width: 1200px;
    margin-top: 24px;
    margin: auto;
    background-color: rgba(32, 34, 48, 0.1);
    .content-item {
      height: 480px;
      box-sizing: border-box;
      padding: 24px;
      display: flex;
      align-items: center;
      flex-direction: row;
      justify-content: space-between;
      &:nth-child(2)  {
        background-color: rgba(37, 41, 56, 0.1);
      }
      &:nth-child(4)  {
        background-color: rgba(37, 41, 56, 0.1);
      }
      .loop-wrap {
        width: 600px;
        border-radius: 8px;
        height: 300px;
        background: rgba(37, 41, 56, 0.1);
        box-sizing: border-box;
        border: 1px solid rgba(42, 44, 57, 0.3);
        .swiper-img {
          width: 100%;
          // height: 100%;
        }
        :deep(.swiper) {
          height: 100%;
        }
        :deep(.swiper-pagination) {
          bottom: 5px !important;
        }
        :deep(.swiper-pagination-bullet) {
          background-color: #7C7F8D;
          opacity: 1;
          width: 6px;
          height: 6px;
        }
        :deep(.swiper-pagination-bullet.swiper-pagination-bullet-active) {
          background-color: #D8D8D8;
        }
      }
      .info-wrap {
        width: 500px;
        .info-title {
          font-weight: bold;
          font-size: 24px;
          line-height: 30px;
          color: #CCCEDA;
        }
        .info-desc {

          font-size: 16px;
          line-height: 28px;
          color: #CCCEDA;
          padding: 15px 0;
        }
        .info-example {

          font-size: 14px;
          line-height: 28px;
          color: #B8BBCC;
        }
      }
    }
  }
  .chat-footer__wrap {
    position: relative;
    &>img {
      width: 100%;
    }
    .footer-content {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      width: 1200px;
      box-sizing: border-box;
      padding: 24px;
      margin: auto;
      z-index: 1;
      display: flex;
      flex-direction: column;
      // align-items: center;
      justify-content: center;
      .footer-title {
        font-size: 24px;
        text-align: center;
        color: #CCCEDA;
      }
    }
  }
}
</style>
